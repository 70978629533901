import styled from "@emotion/styled";
import React from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { useRecoilState } from "recoil";
import { paymentModalState } from "../../recoil/atom/modal";

function PaymentModal() {
  const [modal, setModal] = useRecoilState(paymentModalState);

  const handleClose = () => {
    setModal(null);
  };

  const handleClickGeneralPayment = () => {
    modal?.onPayment && modal.onPayment();
  };

  const handleClickEscrowPayment = () => {
    modal?.onPayment && modal.onPayment();
  };

  if (modal === null) return null;

  return (
    <Wrapper>
      <OutsideClickHandler onOutsideClick={handleClose}>
        <Content>
          <button onClick={handleClickGeneralPayment}>일반결제</button>
          <button onClick={handleClickEscrowPayment}>에스크로결제</button>
        </Content>
      </OutsideClickHandler>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;

  width: 100vw;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const Content = styled.div``;

export default PaymentModal;
