import React, { useEffect } from "react";
import { Route, Switch } from "react-router";
import { BrowserRouter } from "react-router-dom";
import {
  Root,
  Login,
  FindId,
  FindPw,
  Join,
  SnsJoin,
  ProductListPage,
  ProductDetail,
  OrderMemberPage,
  OrderNonmemberPage,
  NonmemberOrderView,
  NonmemberPersonalView,
  MemberOrderView,
  CartPage,
  Mypage,
  EditProfile,
  PersonalDetail,
  PersonalOrderPage,
  PersonalOrderView,
  CouponPage,
  PointPage,
  GradePage,
  CompanyPage,
  TermPage,
  GuidePage,
  RenewallMain,
  RenewallRegister,
  LuxuryMain,
  LuxuryRegister,
  NoticePage,
  NoticeDetail,
  StorePage,
  ReviewPage,
  NaverRedirect,
  NicePayRedirect,
} from "../pages";
import { api, endpoint } from "../utils/api";
import { SWRConfig } from "swr";
import PrivateRoute from "../pages/route/PrivateRoute";
import moment from "moment";
import PageHOC from "../components/PageHOC";
import PaymentModal from "../components/modal/PaymentModal";
import FixedButtons from "../components/common/FixedButtons";

const App = () => {
  const swrConfig = {
    fetcher(url, params) {
      return api.get(url, { params }).then((res) => {
        return res.data?.data || res.data;
      });
    },
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      fetch(`${endpoint}/mypage/info`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }).then(function (response) {
        response.json().then(function ({ data }) {
          localStorage.setItem("user", data?.user_info?.id);
        });
      });
    } else {
      localStorage.removeItem("user");
    }
  }, []);

  const logPageView = () => {
    // console.log(localStorage.getItem("last-time"));
    if (localStorage.getItem("last-time")) {
      //1시간 로그인일때
      let diffTime = moment
        .duration(moment().diff(moment(localStorage.getItem("last-time"))))
        .asHours();

      if (diffTime >= 1) {
        //1시간 경과면 토큰 지우기
        localStorage.clear();
      } else {
        // 1시간 미만이면 시간 업데이트
        localStorage.setItem("last-time", moment());
      }
    }

    if (localStorage.getItem("token")) {
      //로그인한 회원들 쿠폰지급 체크
      fetch(`${endpoint}/coupon/check`, {
        //fetch(`https://api.the-true.co.kr/api/coupon/check`, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          if (data.success === true) {
            // console.log("coupon_check");
          } else {
            localStorage.clear();
            window.alert(data.alert);
          }
        });
    }

    return null;
  };

  return (
    <SWRConfig value={swrConfig}>
      <BrowserRouter>
        <PageHOC />
        <FixedButtons />
        <Route path="/" component={logPageView} />
        <Switch>
          <Route exact path="/" component={Root} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/find_id" component={FindId} />
          <Route exact path="/find_pw" component={FindPw} />
          <Route exact path="/join" component={Join} />
          <Route exact path="/join/sns" component={SnsJoin} />
          <Route exact path="/search/:searchName" component={ProductListPage} />
          <Route exact path="/product/:cateName" component={ProductListPage} />
          <Route
            exact
            path="/product/detail/:detailId"
            component={ProductDetail}
          />
          <Route
            exact
            path="/product/order/member/:orderType/:productId"
            component={OrderMemberPage}
          />
          <Route
            exact
            path="/product/order/nonmember/:orderType/:productId"
            component={OrderNonmemberPage}
          />

          <Route
            exact
            path="/order/view/nonmember"
            component={NonmemberOrderView}
          />
          <Route
            exact
            path="/order/view/nonmember/personal"
            component={NonmemberPersonalView}
          />
          <Route exact path="/cart" component={CartPage} />
          <Route exact path="/company" component={CompanyPage} />
          <Route exact path="/term/:termType" component={TermPage} />
          <Route exact path="/guide" component={GuidePage} />

          {/* 리뉴올 페이지 */}
          <Route exact path="/renewall" component={RenewallMain} />
          <PrivateRoute
            exact
            path="/renewall/register/:type/:codeNumber"
            component={RenewallRegister}
          />

          {/* 명품감정 페이지 */}
          <Route exact path="/luxury" component={LuxuryMain} />
          <PrivateRoute
            exact
            path="/luxury/register/:type/:codeNumber"
            component={LuxuryRegister}
          />

          {/* 공지/이벤트 페이지 */}
          <Route exact path="/notice" component={NoticePage} />
          <Route
            exact
            path="/notice/detail/:noticeId"
            component={NoticeDetail}
          />

          {/* 스토어페이지 */}
          <Route exact path="/store" component={StorePage} />

          {/* 리뷰페이지 */}
          <Route exact path="/review" component={ReviewPage} />

          <Route exact path="/naver/redirect" component={NaverRedirect} />

          {/* 로그인 후 접근 가능  */}
          <PrivateRoute
            exact
            path="/mypage/order/view/:orderNumber"
            component={MemberOrderView}
          />
          <PrivateRoute exact path="/mypage" component={Mypage} />
          <PrivateRoute exact path="/mypage/profile" component={EditProfile} />
          <PrivateRoute exact path="/mypage/coupon" component={CouponPage} />
          <PrivateRoute exact path="/mypage/point" component={PointPage} />
          <PrivateRoute exact path="/mypage/grade" component={GradePage} />

          {/* 개인결제 상세페이지 */}
          <Route
            exact
            path="/personal/detail/:personalType/:codeNumber/:memberType"
            component={PersonalDetail}
          />
          {/* 개인결제 주문페이지 */}
          <Route
            exact
            path="/personal/order/:personalType/:codeNumber/:memberType"
            component={PersonalOrderPage}
          />
          {/* 개인결제 주문조회 */}
          <PrivateRoute
            exact
            path="/mypage/personal/view/:codeNumber"
            component={PersonalOrderView}
          />

          {/* 네이버페이 리다이렉트 */}
          <Route exact path="/naverpay/redirect" component={NaverRedirect} />

          {/* 나이스페이먼츠 리다이렉트 */}
          <Route exact path="/result/:type" component={NicePayRedirect} />
          <PaymentModal />
        </Switch>
      </BrowserRouter>
    </SWRConfig>
  );
};

export default App;
