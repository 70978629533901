import React from "react";
import styled from "@emotion/styled";
import { ReactComponent as KakaoIcon } from "../../assets/images/kakao-icon.svg";
import { ReactComponent as InstagramIcon } from "../../assets/images/instagram-icon-fixed.svg";
import { ReactComponent as CallIcon } from "../../assets/images/call_icon.svg";
import { ReactComponent as TopIcon } from "../../assets/images/top-icon.svg";
import { useHistory } from "react-router";
import { useState } from "react";
import AlertPopup from "../popup/AlertPopup";

function FixedButtons() {
  const history = useHistory();
  const [alertPopup, setAlertPopup] = useState(null);

  return (
    <FixedButtonArea>
      <FixedButton className="guide" onClick={() => history.push("/guide")}>
        <span>더트루</span> 가이드
      </FixedButton>
      <FixedButton
        className="kakao"
        onClick={() => window.open("https://pf.kakao.com/_tnjfb")}
      >
        <KakaoIcon />
        <span>카톡 상담</span>
      </FixedButton>
      <FixedButton
        className="call"
        onClick={() => {
          if (window.innerWidth > 720)
            setAlertPopup({
              title: "통화 연결 불가",
              text: "PC에서는 통화 연결이 어렵습니다.<br/>모바일에서 다시 시도하거나 고객센터 대표번호로 전화 부탁드립니다.<br/><br/>(고객센터) 1668-5667",
            });
          else window.open("tel:1668-5667");
        }}
      >
        <CallIcon />
        <span>전화 상담</span>
      </FixedButton>

      {/*<FixedButton
        className="instagram"
        onClick={() =>
          window.open("https://www.instagram.com/thetrue.official/")
        }
      >
        <InstagramIcon />
        <span>인스타그램</span>
      </FixedButton>*/}
      <FixedButton
        onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
      >
        <TopIcon />
        <span>TOP</span>
      </FixedButton>
      {alertPopup !== null && (
        <AlertPopup
          title={alertPopup.title}
          text={alertPopup.text}
          handleClose={() => setAlertPopup(null)}
        />
      )}
    </FixedButtonArea>
  );
}

const FixedButton = styled.button`
  width: 100%;
  height: 40px;
  border-radius: 100px;
  border: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4px;
  font-size: 14px;
  font-weight: 500;
  background: #fff;

  & > span {
    font-size: 14px;
    font-weight: 500;
  }

  & > svg {
    margin-right: 6px;
  }

  &.guide {
    color: #635040;
    background: #fdf4db;
    border: 1px solid rgba(242, 163, 36, 0.07);
  }
  &.kakao {
    background: #fee500;
    border-color: transparent;
  }
  &.call {
    background: #4a4037;
    border-color: transparent;

    & > span {
      color: #ffecd0;
    }

    & > svg {
      margin-right: 0px;
      & > rect {
        fill: #4a4037;
      }
    }
  }
  &.instagram {
    margin-bottom: 24px;
    color: #fff;
    background: linear-gradient(
      71.57deg,
      #ffd500 8.59%,
      #ff0000 32.81%,
      #8000ff 78.12%
    );
    border: none;
  }

  @media (max-width: 1023px) {
    width: 40px;
    border-radius: 50%;
    font-size: 10px;
    background: #f8f8f8;

    & > svg {
      margin-right: 0px;
    }

    & > span {
      display: none;
    }
    &.instagram {
      margin-bottom: 0;
    }
  }
`;

const FixedButtonArea = styled.div`
  width: 120px;
  position: fixed;
  right: 5%;
  bottom: 15%;
  z-index: 1;

  @media (max-width: 1023px) {
    width: auto;
    right: 16px;
    bottom: 40px;
  }
`;

export default FixedButtons;
