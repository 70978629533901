import React from "react";
import styled from "@emotion/styled";

const Button = ({ onClick, text, icon, ...props }) => {
  return (
    <Btn onClick={onClick} {...props}>
      {icon && <Icon className={icon && text ? "with_text" : ""}></Icon>}
      {text ? <p>{text}</p> : props.children}
    </Btn>
  );
};
const Icon = styled.span`
  width: 24px;
  height: 24px;
  &.with_text {
    margin-right: 8px;
  }
`;
const Btn = styled.button`
  //normal style
  width: 100%;
  font-size: 14px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 0 16px;
  background: #635040;
  border: 1px solid #635040;

  & > p {
    color: #fff;
  }

  &.transparent {
    background: none;
    border-color: transparent;
    & > p {
      color: #635040;
    }
  }

  &.line {
    border-color: #bbb;
    background: none;
    & > p {
      color: #222;
    }

    &.blue {
      color: #635040;
      border-color: #635040;
    }

    &.sky {
      & > p {
        color: #f2a324;
      }
      border-color: #f2a324;
    }

    &.red {
      & > p {
        color: #dd3333;
      }
    }

    &:disabled {
      background: none;
      & > p {
        color: #bbb;
      }
      border-color: #bbb;
    }
  }

  &.round {
    border-radius: 100px;
    background: #f2a324;
    border-color: #f2a324;
    font-size: 18px;
    font-weight: 500;
  }

  &.light_blue_bg {
    background: rgba(242, 163, 36, 0.07);
    border-color: transparent;
    & > p {
      color: #635040;
    }
  }

  &.red_bg {
    background: #dd3333;
    border-color: #dd3333;
  }
  &.sky_bg {
    background: #f2a324;
    border-color: #f2a324;
  }
  &.black_bg {
    background: #222;
    border-color: #222;
  }
  &.auto {
    width: auto;
  }

  //size style
  &.large {
    height: 46px;
    font-size: 16px;
    padding: 0 24px;
  }
  &.small {
    height: 34px;
    font-size: 14px;
    padding: 0 12px;
  }

  &.x_large {
    height: 52px;
    font-size: 16px;
  }

  &:disabled {
    background: #ddd;
    border-color: #ddd;
  }
`;

export default Button;
