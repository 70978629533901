import loadable from "@loadable/component";

export const Root = loadable(() => import("./Root"));
export const Login = loadable(() => import("./user/Login"));
export const FindId = loadable(() => import("./user/FindId"));
export const FindPw = loadable(() => import("./user/FindPw"));
export const Join = loadable(() => import("./user/Join"));
export const SnsJoin = loadable(() => import("./user/SnsJoin"));
export const ProductListPage = loadable(() =>
  import("./product/ProductListPage")
);
export const ProductDetail = loadable(() => import("./product/ProductDetail"));
export const OrderMemberPage = loadable(() =>
  import("./product/OrderMemberPage")
);
export const OrderNonmemberPage = loadable(() =>
  import("./product/OrderNonmemberPage")
);
export const NonmemberOrderView = loadable(() =>
  import("./etc/NonmemberOrderView")
);
export const NonmemberPersonalView = loadable(() =>
  import("./etc/NonmemberPersonalView")
);
export const CartPage = loadable(() => import("./etc/CartPage"));
export const TermPage = loadable(() => import("./etc/TermPage"));
export const CompanyPage = loadable(() => import("./etc/CompanyPage"));

export const GuidePage = loadable(() => import("./guide/GuidePage"));

//리뉴올
export const RenewallMain = loadable(() => import("./renewall/RenewallMain"));
export const RenewallRegister = loadable(() =>
  import("./renewall/RenewallRegister")
);

//명품감정
export const LuxuryMain = loadable(() => import("./luxury/LuxuryMain"));
export const LuxuryRegister = loadable(() => import("./luxury/LuxuryRegister"));

//공지/이벤트
export const NoticePage = loadable(() => import("./notice/NoticePage"));
export const NoticeDetail = loadable(() => import("./notice/NoticeDetail"));

//스토어페이지
export const StorePage = loadable(() => import("./store/StorePage"));

//리뷰페이지
export const ReviewPage = loadable(() => import("./review/ReviewPage"));

//mypage
export const MemberOrderView = loadable(() =>
  import("./mypage/MemberOrderView")
);
export const Mypage = loadable(() => import("./mypage/Mypage"));
export const EditProfile = loadable(() => import("./mypage/EditProfile"));
export const CouponPage = loadable(() => import("./mypage/CouponPage"));
export const PointPage = loadable(() => import("./mypage/PointPage"));
export const GradePage = loadable(() => import("./mypage/GradePage"));

//personal 개인결제
export const PersonalDetail = loadable(() =>
  import("./personal/PersonalDetail")
);
export const PersonalOrderPage = loadable(() =>
  import("./personal/PersonalOrderPage")
);
export const PersonalOrderView = loadable(() =>
  import("./mypage/PersonalOrderView")
);

//네이버 리다이렉트
export const NaverRedirect = loadable(() => import("./redirect/NaverRedirect"));

//나이스페이먼츠 리다이렉트
export const NicePayRedirect = loadable(() =>
  import("./redirect/NicePayRedirect")
);
