import Axios from "axios";
import auth from "./auth";
export const endpoint = process.env.REACT_APP_API_END_POINT;

export const api = Axios.create({
  baseURL: endpoint,
  headers: {
    Authorization: `Bearer ${auth.getToken()}`,
  },
});
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (
      error.response &&
      error.response.status === 401 &&
      !error.config._retry
    ) {
      try {
        auth.clearAppStorage();
        window.location.href = "/login";
      } catch (err) {
        return null;
      }
    }
    return Promise.reject(error);
  }
);
