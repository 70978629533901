import React, { useEffect, useRef } from "react";
import { useLocation, withRouter } from "react-router";
import { useRecoilState } from "recoil";
import { debounce } from "throttle-debounce";
import { pageScrollValueState } from "../recoil/atom/page";

function PageHOC() {
  const { pathname } = useLocation();
  const [pageScroll, setPageScroll] = useRecoilState(pageScrollValueState);

  const scrollValue = useRef(0);

  useEffect(() => {
    if (pageScroll[pathname]) {
      window.scrollTo(0, pageScroll[pathname]);
    } else {
      window.scrollTo(0, 0);
    }

    return () => {
      if (scrollValue.current === null) return;
      if (pageScroll[pathname] === scrollValue.current) return;

      setPageScroll({
        ...pageScroll,
        [pathname]: scrollValue.current,
      });
      scrollValue.current = null;
    };
  }, [pathname, pageScroll, setPageScroll]);

  useEffect(() => {
    const scrollHandler = debounce(200, () => {
      const scrollY = window?.scrollY || 0;
      scrollValue.current = scrollY;
    });

    window.addEventListener("scroll", scrollHandler);

    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  }, []);

  return null;
}

export default withRouter(PageHOC);
